import React from 'react';
import { translate, InjectedTranslateProps } from 'yoshi-flow-editor-runtime';
import { PolicySectionViewModel } from '../../../../service-page-view-model/policy-section-view-model/policySectionViewModel';
import Section from '../Section';
import { SectionTypes } from '../../types';
import SectionTitle from '../SectionTitle/SectionTitle';
import SectionText from '../SectionText/SectionText';

export type PolicyProps = InjectedTranslateProps & {
  viewModel?: PolicySectionViewModel;
  title: string;
  className?: string;
};

const PolicyComponent = ({ t, viewModel, title, className }: PolicyProps) => {
  const policySectionTitle = () => (
    <SectionTitle
      title={
        title || t('app.settings.sections.sub-settings.policy.default.title')
      }
      dataHook="policy-title"
      className={className}
    />
  );

  const hasCancellationPolicy = !!viewModel?.cancellationPolicy;

  const cancellationPolicy = () => (
    <SectionText
      text={viewModel?.cancellationPolicy}
      dataHook="cancellation-policy"
      className={className}
    />
  );

  return hasCancellationPolicy ? (
    <Section sectionType={SectionTypes.POLICY} data-hook="policy-section">
      {policySectionTitle()}
      {cancellationPolicy()}
    </Section>
  ) : null;
};

export const Policy = translate()(PolicyComponent);
