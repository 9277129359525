import React from 'react';
import { SectionTypes, AlignmentOptions } from '../../types';
import BookButton from '../BookButton/BookButton';
import { st, classes } from './Section.st.css';
import { Divider } from 'wix-ui-tpa/Divider';

interface SectionProps {
  withBookButton?: boolean;
  sectionType: SectionTypes;
  buttonAlignment?: AlignmentOptions;
  className?: string;
}

const Section: React.FC<SectionProps> = ({
  children,
  sectionType,
  withBookButton,
  className,
  buttonAlignment = AlignmentOptions.CENTER,
  ...rest
}) => {
  return (
    <>
      <div className={classes.root} {...rest}>
        {sectionType !== SectionTypes.DETAILS && (
          <Divider
            data-hook={`divider-${sectionType}`}
            className={classes.divider}
          />
        )}
        {children}
        {withBookButton && (
          <div
            className={st(
              classes.buttonWrapper,
              { alignment: buttonAlignment },
              className,
            )}
          >
            <BookButton dataHook={`book-button-${sectionType}`} />
          </div>
        )}
      </div>
    </>
  );
};

export default Section;
