import React from 'react';
import {
  InjectedExperimentsProps,
  InjectedTranslateProps,
  translate,
  withExperiments,
} from 'yoshi-flow-editor-runtime';
import { ISection, SectionTypes } from '../../types';
import { DescriptionSectionViewModel } from '../../../../service-page-view-model/description-section-view-modal/descriptionSectionViewModel';
import Section from '../Section';
import SectionTitle from '../SectionTitle/SectionTitle';
import SectionText from '../SectionText/SectionText';

type DescriptionProps = InjectedExperimentsProps &
  InjectedTranslateProps & {
    descriptionTitleText: string;
    viewModel?: DescriptionSectionViewModel;
    section: ISection;
    className?: string;
  };

export default translate()(
  withExperiments<DescriptionProps>(
    ({ t, descriptionTitleText, viewModel, section, className }) => {
      return (
        <Section
          sectionType={SectionTypes.DETAILS}
          withBookButton={section.bookButton}
        >
          {viewModel?.description && (
            <div data-hook="description-wrapper">
              <SectionTitle
                title={descriptionTitleText || t('app.description.title')}
                className={className}
                dataHook="description-title"
              />
              <SectionText
                text={viewModel.description}
                className={className}
                dataHook="description"
              />
            </div>
          )}
        </Section>
      );
    },
  ),
);
