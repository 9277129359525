import React from 'react';
import {
  InjectedExperimentsProps,
  InjectedTranslateProps,
  translate,
  withExperiments,
} from 'yoshi-flow-editor-runtime';
import { st, classes } from './Widget.st.css';
import TitleAndTagline from './TitleAndTagline';
import Details from './Details';
import Contact from './Contact';
import { ISection, SectionTypes } from '../types';
import Header from './Header/Header';
import { componentSettings } from '../componentSettings';
import { ISettingsValues } from '@wix/tpa-settings';
import { ServicePageViewModel } from '../../../service-page-view-model/servicePageViewModel';
import EmptyState from './EmptyState';
import Description from './Description';
import Sidebar from './Sidebar/Sidebar';
import { WidgetSettingsProvider } from './useWidgetSettings';
import { Policy } from './Policy/Policy';
import { IHostProps } from '@wix/native-components-infra/dist/src/types/types';
import { TPAComponentsProvider } from 'wix-ui-tpa/TPAComponentsConfig';
import BodyComponent from './Body/Body';

export interface ControllerProps {
  settings: ISettingsValues<typeof componentSettings>;
  viewModel?: ServicePageViewModel;
  className?: string;
  host: IHostProps;
  isMobile: boolean;
  isRTL: boolean;
}

type WidgetProps = InjectedExperimentsProps &
  InjectedTranslateProps &
  ControllerProps;

const getSectionComponentByType = (
  section: ISection,
  settings: ISettingsValues<typeof componentSettings>,
  viewModel: ServicePageViewModel,
) => {
  switch (section.type) {
    case SectionTypes.TITLE_TAGLINE:
      return (
        <TitleAndTagline
          key={section.type}
          section={section}
          serviceTaglineVisibility={settings.serviceTaglineVisibility}
          titleAndTaglineAlignment={settings.titleAndTaglineAlignment}
          serviceTitleVisibility={settings.serviceTitleVisibility}
          onlineBadgeText={settings.onlineBadgeText}
          onlineBadgeVisibility={settings.onlineBadgeVisibility}
          viewModel={viewModel.titleAndTagline}
        />
      );
    case SectionTypes.SCHEDULING:
      return (
        <div key={section.type} style={{ padding: '32px', fontSize: '16px' }}>
          scheduling
        </div>
      );
    case SectionTypes.POLICY:
      return (
        <Policy
          key={section.type}
          title={settings.policySectionTitle}
          viewModel={viewModel.policySection}
        />
      );
    case SectionTypes.DETAILS:
      return (
        <Details
          key={section.type}
          section={section}
          detailsDaysVisibility={settings.detailsDaysVisibility}
          detailsDurationVisibility={settings.detailsDurationVisibility}
          detailsLocationVisibility={settings.detailsLocationVisibility}
          detailsPriceVisibility={settings.detailsPriceVisibility}
          detailsTextAlignment={settings.detailsTextAlignment}
          detailsBoxAlignment={settings.detailsBoxAlignment}
          detailsButtonAlignment={settings.detailsButtonAlignment}
          viewModel={viewModel.detailsSection}
        />
      );
    case SectionTypes.DESCRIPTION:
      return (
        <Description
          key={section.type}
          section={section}
          descriptionTitleText={settings.descriptionTitleText}
          viewModel={viewModel.descriptionSection}
        />
      );
    case SectionTypes.CONTACT:
      return (
        <Contact
          key={section.type}
          section={section}
          contactDetailsTitleText={settings.contactDetailsTitleText}
          viewModel={viewModel.contactSection}
          businessAddressVisibility={settings.businessAddressVisibility}
          businessEmailVisibility={settings.businessEmailVisibility}
          businessPhoneNumberVisibility={settings.businessPhoneNumberVisibility}
        />
      );
    default:
      return null;
  }
};

export default translate()(
  withExperiments<WidgetProps>(
    ({ t, settings, viewModel, host, className, isMobile, isRTL }) => {
      const getSectionByType = (sectionType: SectionTypes): ISection => {
        return settings.sections.find(
          ({ type }) => type === sectionType,
        ) as ISection;
      };
      return (
        <TPAComponentsProvider value={{ mobile: isMobile, rtl: isRTL }}>
          <WidgetSettingsProvider value={settings}>
            {viewModel ? (
              <div
                className={st(classes.root, {}, className)}
                data-hook="booking-service-page-wrapper"
              >
                {settings.headerVisibility && (
                  <Header
                    viewModel={viewModel.header}
                    serviceTitleVisibility={settings.headerTitleVisibility}
                    headerTitleAlignment={settings.headerTitleAlignment}
                    imageVisibility={settings.headerImageVisibility}
                    bookButtonVisibility={settings.headerBookButtonVisibility}
                    headerImagePosition={settings.headerImagePosition}
                    isFullWidth={
                      host.dimensions.width === '100%' ||
                      host.dimensions.width === ''
                    }
                  />
                )}
                <div
                  className={st(
                    classes.dynamicWrapper,
                    {
                      sidebarPosition: settings.sidebarPosition,
                      isMobile,
                    },
                    className,
                  )}
                >
                  <BodyComponent
                    width={`calc(${100 - settings.sidebarWidth}% - ${
                      settings.sidebarSideSpacing
                    }px)`}
                  >
                    {settings.sections.map((section) =>
                      section.visible &&
                      section.type !== settings.sidebarSection
                        ? getSectionComponentByType(
                            section,
                            settings,
                            viewModel,
                          )
                        : null,
                    )}
                  </BodyComponent>
                  {settings.sidebarVisibility && (
                    <Sidebar
                      sidebarFreezePosition={settings.sidebarFreezePosition}
                      width={`calc(${settings.sidebarWidth}% - ${settings.sidebarSideSpacing}px`}
                    >
                      {getSectionComponentByType(
                        getSectionByType(settings.sidebarSection),
                        settings,
                        viewModel,
                      )}
                    </Sidebar>
                  )}
                </div>
              </div>
            ) : (
              <EmptyState />
            )}
          </WidgetSettingsProvider>
        </TPAComponentsProvider>
      );
    },
  ),
);
