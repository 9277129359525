import React from 'react';
import { st, classes } from './Body.st.css';
import { MainComponents } from '../../types';
import { RenderLocationProvider } from '../useRenderLocation';

type BodyProps = {
  width: string;
  className?: string;
};

const BodyComponent: React.FC<BodyProps> = ({ width, className, children }) => {
  return (
    <RenderLocationProvider value={MainComponents.BODY}>
      <div style={{ flexBasis: width }} className={st(classes.root, className)}>
        {children}
      </div>
    </RenderLocationProvider>
  );
};

export default BodyComponent;
