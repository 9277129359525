import React from 'react';
import {
  AlignmentOptions,
  ImagePositionOptions,
  SectionTypes,
  SidebarPosition,
} from '../types';
import { ISettingsValues } from '@wix/tpa-settings';
import { componentSettings, defaultSections } from '../componentSettings';

export const WidgetSettingsContext = React.createContext<
  ISettingsValues<typeof componentSettings>
>({
  businessAddressVisibility: true,
  businessEmailVisibility: true,
  businessPhoneNumberVisibility: true,
  contactDetailsTitleText:
    'app.settings.sections.contact.default.contact-details-title.label',
  sidebarWidth: 80,
  detailsBoxAlignment: AlignmentOptions.STRETCH,
  detailsButtonAlignment: AlignmentOptions.LEFT,
  detailsDaysVisibility: true,
  detailsDurationVisibility: true,
  detailsLocationVisibility: true,
  detailsPriceVisibility: true,
  onlineBadgeVisibility: true,
  onlineBadgeText: '',
  detailsTextAlignment: AlignmentOptions.CENTER,
  headerVisibility: true,
  headerTitleVisibility: true,
  headerTitleAlignment: AlignmentOptions.CENTER,
  headerImageVisibility: true,
  headerBookButtonVisibility: true,
  headerImagePosition: ImagePositionOptions.MIDDLE,
  policySectionTitle: '',
  sections: defaultSections,
  serviceTaglineVisibility: true,
  serviceTitleVisibility: true,
  sidebarVisibility: true,
  titleAndTaglineAlignment: AlignmentOptions.CENTER,
  titleAndTaglineVisibility: true,
  descriptionTitleText: '',
  bookButtonText: '',
  serviceUnavailableMessageText: '',
  fullCourseMessageText: '',
  unbookableCourseMessageText: '',
  sidebarSection: SectionTypes.DETAILS,
  sidebarFreezePosition: true,
  sidebarPosition: SidebarPosition.RIGHT,
  sidebarAlignment: AlignmentOptions.LEFT,
  sidebarSideSpacing: 0,
});

export const WidgetSettingsConsumer = WidgetSettingsContext.Consumer;
export const WidgetSettingsProvider = WidgetSettingsContext.Provider;

export const useWidgetSettings = () => React.useContext(WidgetSettingsContext);
